<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="1M"
            label="변경 작업 기간"
            name="mocPeriod"
            v-model="searchParam.mocPeriod"
          />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="발의부서" name="initiativeDeptCd" v-model="searchParam.initiativeDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="stepItems"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="mocStepCd"
            label="진행단계"
            v-model="searchParam.mocStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="임시변경-MOC 목록"
      tableId="moctemp01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add_circle" @btnClicked="openPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="table-chip">
        <div style="font-size:0.9em; padding-left: 10px;"><span class="text-red-7 text-bold">△</span> : 대상-미추가&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-bold text-green">O</span> : 대상-추가</div>
      </template>
      <template v-slot:customArea="{ props, col }">
        <div v-if="props.row[col.name]"
          :class="[
            props.row[col.name] === 'O' || props.row[col.name] === 'Y' ? 'text-green' : 'text-red-7', 
            'text-bold'
          ]">
          {{ props.row[col.name] === 'Y' ? 'O' : props.row[col.name] === 'N' ? 'X' : props.row[col.name] }}
        </div>
        <template v-else></template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-temp',
  data() {
    return {
      searchParam: {
        plantCd: null,
        mocPeriod: [],
        initiativeDeptCd: null,
        mocStepCd: null,
        mocTypeCd: 'MT00000005',
      },
      grid: {
        merge: [
          { index: 0, colName: 'initiativeYear' },
        ],
        columns: [
          {
            name: 'initiativeYear',
            field: 'initiativeYear',
            label: '년도',
            align: 'center',
            style: 'width:60px',
            sortable: true,
            fix: true,
          },
          {
            name: 'yearRank',
            field: 'yearRank',
            label: '#',
            align: 'center',
            style: 'width:45px',
            sortable: true,
            fix: true,
          },
          {
            name: 'initiativeDt',
            field: 'initiativeDt',
            label: '발의일',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            fix: true,
          },
          {
            name: 'mocTitle',
            field: 'mocTitle',
            label: '제목',
            align: 'left',
            sortable: true,
            style: 'width:280px',
            type: 'link',
            fix: true,
          },
          {
            name: 'mocStepName',
            field: 'mocStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            fix: true,
          },
          // {
          //   name: 'bizApprStepNm',
          //   field: 'bizApprStepNm',
          //   label: '결재진행단계',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          {
            name: 'mocTypeName',
            field: 'mocTypeName',
            label: '변경구분',
            align: 'center',
            style: 'width:70px',
            sortable: true,
            fix: true,
          },
          {
            name: 'changeFlag',
            field: 'changeFlag',
            label: '변경관리위원회 승인여부',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'psi',
            field: 'psi',
            label: '공정안전자료',
            child: [
              {
                name: 'pidFlag',
                field: 'pidFlag',
                label: 'P&ID',
                align: 'center',
                style: 'width:70px',
                sortable: true,
                type: 'custom',
              },
              {
                name: 'powerMachineFlag',
                field: 'powerMachineFlag',
                label: '동력기계',
                align: 'center',
                style: 'width:70px',
                sortable: true,
                type: 'custom',
              },
              {
                name: 'deviceEquipmentFlag',
                field: 'deviceEquipmentFlag',
                label: '장치 및 설비',
                align: 'center',
                style: 'width:90px',
                sortable: true,
                type: 'custom',
              },
              {
                name: 'pipeGasketFlag',
                field: 'pipeGasketFlag',
                label: '배관 및 개스킷',
                align: 'center',
                style: 'width:70px',
                sortable: true,
                type: 'custom',
              },
              {
                name: 'safetyValveRuptureDiskFlag',
                field: 'safetyValveRuptureDiskFlag',
                label: '배관 및 개스킷',
                align: 'center',
                style: 'width:80px',
                sortable: true,
                type: 'custom',
              },
              {
                name: 'documentFlag',
                field: 'documentFlag',
                label: '절차서',
                align: 'center',
                style: 'width:70px',
                sortable: true,
                type: 'custom',
              },
            ]
          },
          {
            name: 'riskAssessFlag',
            field: 'riskAssessFlag',
            label: '위험성평가',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'preStartFlag',
            field: 'preStartFlag',
            label: '가동전점검',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'workpermitFlag',
            field: 'workpermitFlag',
            label: '안전작업허가',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'eduFlag',
            field: 'eduFlag',
            label: '교육',
            align: 'center',
            style: 'width:60px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'licenseFlag',
            field: 'licenseFlag',
            label: '인허가',
            align: 'center',
            style: 'width:60px',
            sortable: true,
            type: 'custom',
          },
          {
            name: 'mocNo',
            field: 'mocNo',
            label: '관리번호',
            align: 'center',
            style: 'width:110px',
            sortable: true
          },
          {
            name: 'mocDates',
            field: 'mocDates',
            label: '변경 작업 기간',
            align: 'center',
            style: 'width:170px',
            sortable: true
          },
          {
            name: 'initiativeUserName',
            field: 'initiativeUserName',
            label: '발의자',
            align: 'center',
            style: 'width:80px',
            sortable: true
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.moc.change.list.url
      // code setting
      this.$comm.getStepItems('MOC_TEMP_STEP_CD').then(_result => {
        let idx = this.$_.findIndex(_result, { stepperMstCd: 'MT00000020' });
        this.$set(_result[idx], 'stepperMstNm', _result[idx - 1].stepperMstNm + ' / ' + _result[idx].stepperMstNm)
        _result = this.$_.reject(_result, { stepperMstCd: 'MT00000015' })
        this.stepItems = _result;
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (!row) return;
      this.openPopup(row)
    },
    openPopup(row) {
      this.popupOptions.title = '변경관리 상세'; 
      this.popupOptions.param = {
        sopMocId: row ? row.sopMocId : '',
        mocTypeCd: row ? row.mocTypeCd : 'MT00000005',
        mocStepCd: row ? row.mocStepCd : '',
      };
      this.popupOptions.target = () => import(`${'./mocDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
